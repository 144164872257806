import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/constants', config);
}

const get = (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/constants/' + id);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/constants', data);
}

const update = async (key, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/constants/value/' + key, formData);
}

const delConstant = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/constants/' + id);
}

export default {
    getAll,
    get,
    store,
    update,
    delConstant
}
